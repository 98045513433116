export default defineNuxtRouteMiddleware(async () => {
  const auth = useState('auth')
  const { fetchSignatoryProperties } = useUserHouses()
  const { fetchContracts } = useUserContracts()

  if (auth.value.authenticated) {
    try {
      await Promise.all([fetchSignatoryProperties(), fetchContracts()])
    } catch (err) {
      console.error(err)
    }
  }
})
